import { TOPIC_ACTIONS } from "./action";

// ----------------------------------------------------------------------

const topicInitialState = {
  data: [],
  userData: {},
  loading: false,
  pagination: {
    currentPage: 0,
    totalPages: 1,
    perPage: 1,
    total: 1,
  },
};

// eslint-disable-next-line default-param-last
export function topicReducer(state = topicInitialState, action) {
  switch (action.type) {
    case TOPIC_ACTIONS.SET_STATE:
      return { ...state, ...action.payload };
    case TOPIC_ACTIONS.DESTROY:
      return topicInitialState;
    default:
      return state;
  }
}
