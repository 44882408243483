import axiosClient from "../client";
import { USER_API_ROUTES } from "./constants";
import * as qs from 'querystring'

export class SubscriptionAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getSubscriptions = async ({query}) => {
    return await this.client.get(`${USER_API_ROUTES.GET_SUBSCRIPTION}?${qs.stringify(query)}`);
  };
}
