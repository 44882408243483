import { all, put, takeEvery } from "redux-saga/effects";
import { SubscriptionAPIService } from "../../services/subscription/subscription";
import { doApiCall } from "../helper";
import { SUBSCRIPTION_ACTIONS } from "./action";

const subscriptionService = new SubscriptionAPIService();

export function* GET_SUBSCRIPTION({ payload }) {
  const response = yield doApiCall(
    subscriptionService.getSubscriptions,
    payload,
    SUBSCRIPTION_ACTIONS.SET_STATE
  );

  if (response.success) {
    const {
      data,
      pagination,
    } = response;

    yield put({
      type: SUBSCRIPTION_ACTIONS.SET_STATE,
      payload: {
        data,
        pagination,
      },
    });
  }
}

export function* subscriptionSaga() {
  yield all([
    takeEvery(SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION, GET_SUBSCRIPTION),
  ]);
}
