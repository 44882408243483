import { notification } from "antd";
import { all, put, takeEvery } from "redux-saga/effects";
import { TopicsAPIService } from "../../services/topics/topics";
import { doApiCall } from "../helper";
import { TOPIC_ACTIONS } from "./action";

const topicsService = new TopicsAPIService();

export function* GET_TOPICS({ payload }) {
  const response = yield doApiCall(
    topicsService.getTopics,
    payload,
    TOPIC_ACTIONS.SET_STATE
  );

  if (response.success) {
    const {
      data,
     pagination,
    } = response;
    yield put({
      type: TOPIC_ACTIONS.SET_STATE,
      payload: {
        isFreeTopics: false,
        data,
        pagination,
      },
    });
  }
}

export function* GET_FREE_TOPICS({ payload }) {
  const response = yield doApiCall(
    topicsService.getFreeTopics,
    payload,
    TOPIC_ACTIONS.SET_STATE
  );

  if (response.success) {
    const {
      data,
      pagination,
    } = response;
    
    yield put({
      type: TOPIC_ACTIONS.SET_STATE,
      payload: {
        isFreeTopics: true,
        data,
        pagination,
      },
    });
  }
}

// export function* CREATE_USER({ payload }) {
//   const response = yield doApiCall(
//     userService.createUser,
//     payload,
//     USER_ACTIONS.SET_STATE
//   );

//   if (response.success) {
//     const { data } = response;
//     yield put({
//       type: USER_ACTIONS.SET_STATE,
//       payload: {
//         ...data,
//       },
//     });

//     notification.success("User added successfull", {
//       position: "top-right",
//       autoClose: "1000",
//       hideProgressBar: false,
//       closeButton: true,
//       closeOnClick: true,
//       draggable: false,
//       progress: undefined,
//       theme: "colored",
//     });
//   }
// }

// export function* GET_USER_BY_ID({ payload }) {
//   const response = yield doApiCall(
//     userService.getUserById,
//     payload,
//     USER_ACTIONS.SET_STATE
//   );
//   if (response.success) {
//     const { data } = response;
//     yield put({
//       type: USER_ACTIONS.SET_STATE,
//       payload: {
//         userData: data,
//       },
//     });
//   }
// }

// export function* UPDATE_USER({ payload }) {
//   const response = yield doApiCall(
//     userService.updateUser,
//     payload,
//     USER_ACTIONS.SET_STATE
//   );

//   if (response.success) {
//     const { data } = response;
//     yield put({
//       type: USER_ACTIONS.SET_STATE,
//       payload: {
//         ...data,
//       },
//     });

//     notification.success("User updated successfull", {
//       position: "top-right",
//       autoClose: "1000",
//       hideProgressBar: false,
//       closeButton: true,
//       closeOnClick: true,
//       draggable: false,
//       progress: undefined,
//       theme: "colored",
//     });
//   }
// }

export function* topicSaga() {
  yield all([
    takeEvery(TOPIC_ACTIONS.GET_TOPICS, GET_TOPICS),
    takeEvery(TOPIC_ACTIONS.GET_FREE_TOPICS, GET_FREE_TOPICS),
    // takeEvery(USER_ACTIONS.GET_USER_BY_ID, GET_USER_BY_ID),
    // takeEvery(USER_ACTIONS.UPDATE_USER, UPDATE_USER),
  ]);
}
