import { all } from "redux-saga/effects";
import { authSaga } from "./auth/saga";
import { userSaga } from "./user/saga";
import { subjectSaga } from "./subjects/saga";
import { chapterSaga } from "./chapters/saga";
import { topicSaga } from "./topics/saga";
import { subscriptionSaga } from "./subscription/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    subjectSaga(),
    chapterSaga(),
    topicSaga(),
    subscriptionSaga()
  ]);
}
