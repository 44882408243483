import { notification } from "antd";
import { all, put, takeEvery } from "redux-saga/effects";
import store from "store";
import { AuthAPIService } from "../../services/auth/auth";
import { doApiCall } from "../helper";
import { AUTH_ACTIONS } from "./actions";
import { UserAPIService } from "../../services/user/user";
import { history } from "../../store";
import { CHAPTER_ACTIONS } from "../chapters/action";
import { SUBJECT_ACTIONS } from "../subjects/action";
import { TOPIC_ACTIONS } from "../topics/action";
import { whitelistedRoutes } from "../../utils/Constants";

const authService = new AuthAPIService();
const userService = new UserAPIService();

export function* LOGIN({ payload }) {
  const response = yield doApiCall(
    authService.login,
    payload,
    AUTH_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { tokens, user } = response?.data;
    yield put({
      type: AUTH_ACTIONS.SET_STATE,
      payload: {
        user,
        isAuthenticated: true,
      },
    });

    store.set("accessToken", tokens.accessToken);
    store.set("refreshToken", tokens.refreshToken);

    notification.success({
      message: "Login successfull",
      description: "You have been logged in",
    });

    yield history.push("/course-content");
  }
}

export function* REGISTRATION({ payload }) {
  const response = yield doApiCall(
    authService.registration,
    payload,
    AUTH_ACTIONS.SET_STATE
  );

  if (response.success) {
    const {
      body: { email, password },
    } = payload;
    yield put({
      type: AUTH_ACTIONS.LOGIN,
      payload: {
        body: {
          email,
          password,
        },
      },
    });
  }
}

export function* CURRENT_USER() {
  const response = yield doApiCall(
    userService.getSelfProfile,
    {},
    AUTH_ACTIONS.SET_STATE
  );

  if (response.email) {
    yield put({
      type: AUTH_ACTIONS.SET_STATE,
      payload: {
        user: response,
        isAuthenticated: true,
      },
    });
  } else {
    const redirect = store.get("redirectingFrom");
    store.clearAll();
    store.set("redirectingFrom", redirect);

    yield put({
      type: AUTH_ACTIONS.DESTROY,
    });

    if (!whitelistedRoutes.includes(redirect)) history.push("/auth");

    const token = store.get("accessToken");

    if (typeof token !== "undefined")
      notification.error({
        message: "Unauthorised",
        description: "Session expired, login again.",
      });
  }
}

export function* LOGOUT() {
  store.clearAll();
  yield put({
    type: AUTH_ACTIONS.DESTROY,
  });

  yield put({
    type: CHAPTER_ACTIONS.DESTROY,
  });

  yield put({
    type: SUBJECT_ACTIONS.DESTROY,
  });

  yield put({
    type: TOPIC_ACTIONS.DESTROY,
  });

  history.push("/auth");
}

export function* authSaga() {
  yield all([
    takeEvery(AUTH_ACTIONS.LOGIN, LOGIN),
    takeEvery(AUTH_ACTIONS.REGISTER, REGISTRATION),
    takeEvery(AUTH_ACTIONS.LOGOUT, LOGOUT),
    CURRENT_USER(),
  ]);
}
