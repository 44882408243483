import { Col, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PricingTable from "./PricingTable";
import { SUBSCRIPTION_ACTIONS } from "../redux/subscription/action";

const Subscription = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();

  const {
    subscriptionReducer: { data: subData },
    authReducer: { user:{isValid, subscriptionId} },
  } = useSelector((s) => s);

  const load = () => {
    dispatch({
      type: SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION,
      payload: {
        query: {
          perPage: 1000000,
        },
      },
    });
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Row>
      {isValid ? (
        <>
          <Col xs={24}>
            <Title pt={1} className={""}>
              Active Subscription
            </Title>
          </Col>
          <Col xs={24} className={"centre"} pb={10}>
            <PricingTable
              subData={subData.filter((sub) => sub.id === subscriptionId)}
              isValid={isValid}
            />
          </Col>
        </>
      ) : (
        <>
          <Col xs={24}>
            <Title pt={1} className={""}>
              Subscription Plans
            </Title>
          </Col>
          <Col xs={24} className={"centre"} pb={10}>
            <PricingTable subData={subData} />
          </Col>
        </>
      )}
    </Row>
  );
};

export default Subscription;
