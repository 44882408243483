import axiosClient from "../client";
import { USER_API_ROUTES } from "./constants";
import * as qs from 'querystring'

export class TopicsAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getTopics = async ({params, query}) => {
    return this.client.get(`${USER_API_ROUTES.GET_TOPICS.replace(":id", params.chapterId)}?${qs.stringify(query)}`);
  };

  getFreeTopics = async ({query}) => {
    return this.client.get(`${USER_API_ROUTES.GET_FREE_TOPICS}?${qs.stringify(query)}`);
  };

  getTopicById = async ({params}) => {
    return this.client.get(USER_API_ROUTES.GET_TOPIC_BY_ID.replace(":id", params.topicId));
  };
}
