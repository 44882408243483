import axiosClient from "../client";
import { USER_API_ROUTES } from "./constants";
import * as qs from 'querystring'

export class SubjectsAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getSubjects = async ({query}) => {
    return await this.client.get(`${USER_API_ROUTES.GET_SUBJECTS}?${qs.stringify(query)}`);
  };

  getSubjectById = async ({params}) => {
    return await this.client.get(USER_API_ROUTES.GET_SUBJECT_BY_ID.replace(":id", params.subjectId));
  };
}
