import axiosClient from "../client";
import { USER_API_ROUTES } from "./constants";
import * as qs from 'querystring'

export class ChaptersAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getChapters = async ({params, query}) => {
    return this.client.get(`${USER_API_ROUTES.GET_CHAPTERS.replace(":id", params.subjectId)}?${qs.stringify(query)}`);
  };

  getChapterById = async ({params}) => {
    return this.client.get(USER_API_ROUTES.GET_CHAPTER_BY_ID.replace(":id", params.chapterId));
  };
}
