import axiosClient from "../client";
import { USER_API_ROUTES } from "./constants";

export class UserAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getSelfProfile = async () => {
    return this.client.get(USER_API_ROUTES.GET_SELF_PROFILE);
  };
}
