import axiosClient from "../client";
import { AUTH_API_ROUTES } from "./constants";
// import { AUTH_API_ROUTES } from "./constants";

const demoCredentials = {
  userName: "demo@eduthum.com",
  password: "123456789",
};

export class AuthAPIService {
  constructor() {
    this.client = axiosClient;
  }

  login = async ({ body }) => {
    return this.client.post(AUTH_API_ROUTES.LOGIN, body);
  };

  registration = async ({ body }) => {
    return this.client.post(AUTH_API_ROUTES.REGISTRATION, body);
  };
}
