import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { history } from "../store";

const NotFound = () => {
  const { isAuthenticated } = useSelector((reducers) => {
    return reducers.authReducer;
  });

  if (!isAuthenticated) {
    history.push("/auth");
  }

  return (
    <div>
    <h1>Page Not Found!</h1>
    <Link to="/course-content">Go Home</Link>
  </div>
  )
};

export default NotFound;
