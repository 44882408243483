import { all, put, takeEvery } from "redux-saga/effects";
import { SubjectsAPIService } from "../../services/subjects/subjects";
import { doApiCall } from "../helper";
import { SUBJECT_ACTIONS } from "./action";

const subjectService = new SubjectsAPIService();

export function* GET_SUBJECTS({ payload }) {
  const response = yield doApiCall(
    subjectService.getSubjects,
    payload,
    SUBJECT_ACTIONS.SET_STATE
  );

  if (response.success) {
    const {
      data,
      pagination,
    } = response;

    yield put({
      type: SUBJECT_ACTIONS.SET_STATE,
      payload: {
        data,
        pagination,
      },
    });
  }
}

export function* GET_SUBJECT_BY_ID({ payload }) {
  const response = yield doApiCall(
    subjectService.getSubjectById,
    payload,
    SUBJECT_ACTIONS.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: SUBJECT_ACTIONS.SET_STATE,
      payload: {
        subjectData: data,
      },
    });
  }
}

export function* subjectSaga() {
  yield all([
    takeEvery(SUBJECT_ACTIONS.GET_SUBJECTS, GET_SUBJECTS),
    takeEvery(SUBJECT_ACTIONS.GET_SUBJECT_BY_ID, GET_SUBJECT_BY_ID),
  ]);
}
