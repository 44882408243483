import React, { useState } from "react";
import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import logo from "../assets/images/logo.png";

import { AUTH_ACTIONS } from "../redux/auth/actions";
import { history } from "../store";

const { Title, Text } = Typography;
const { Content } = Layout;

function Registration() {
  const dispatch = useDispatch();

  const [error, setError] = useState(null);

  const { loading } = useSelector((reducers) => {
    return reducers.authReducer;
  });

  const onFinish = ({ userName, password, confirmPassword }) => {
    dispatch({
      type: AUTH_ACTIONS.REGISTER,
      payload: {
        body: {
          email: userName,
          password,
          confirmPassword,
        },
      },
    });
  };

  const handleLoginClick = () => {
    history.push("/login");
  };

  const handleBackToWebsite = () => {
    window.location.href = "https://eduthum.com";
  };

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row
            gutter={[24, 0]}
            justify={"center"}
            align={"middle"}
            style={{ height: "calc(100vh - 40px)" }}
          >
            <Col
              xs={24}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "500px",
              }}
            >
              <img src={logo} alt="logo" />
              <Title>Registration</Title>
              <Text className="font-regular text-muted" level={5}>
                Create you credentials.
              </Text>
              <Form
                onFinish={onFinish}
                layout="vertical"
                className="row-col"
                style={{
                  width: "100%",
                }}
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="userName"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item
                  className="userName"
                  label="Confirm password"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Confirm password" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loading}
                  >
                    Register
                  </Button>
                </Form.Item>
                <Button
                  type="default"
                  onClick={handleBackToWebsite}
                  style={{ width: "100%" }}
                >
                  Back to Website
                </Button>
              </Form>
              {error && <span>{error}</span>}
              <div style={{ textAlign: "center" }}>
                Already Registered?{" "}
                <Button type="link" onClick={handleLoginClick}>
                  Login.
                </Button>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}

export default Registration;
